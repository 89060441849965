import {
    Box,
    Button,
    ButtonGroup, Card,
    Container,
    Divider,
    Flex, Hide,
    HStack,
    IconButton, Image,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    InputGroup, Select, Stack,
    useBreakpointValue,
    useColorModeValue, VStack, useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiBarChart2, FiBookmark,
    FiCheckSquare, FiEdit, FiFilePlus,
    FiHelpCircle,
    FiHome,
    FiMenu,
    FiMonitor,
    FiMoon, FiRepeat,
    FiSearch,
    FiSettings,
    FiSun, FiUsers, FiWatch
} from 'react-icons/fi'
import {GeneralInfo} from "../GeneralInfo";
import {ProcessMetrics} from "../process/ProcessMetrics";
import {CodeMetrics} from "../code/CodeMetrics";
import {DeveloperMetrics} from "../developer/DeveloperMetrics";
import {useQuery} from "react-query";
import axios from "axios";
import {TimeMetrics} from "../time/TimeMetrics";
import {CommentaryView} from "../commentary/CommentaryView";
import {LoadingView} from "../shared/LoadingView";
import {ErrorView} from "../shared/ErrorView";
import {NoDataView} from "../shared/NoDataView";
import {ScanOptions} from "./ScanOptions";
import {useAuth0} from "@auth0/auth0-react";
import {NavButton} from "./NavButton";
import {ToggleButton} from "./ToggleButton";

export const TopMenu = () => {
    const [teamId, setTeamId] = React.useState();
    const [scanId, setScanId] = React.useState();
    const [section, setSection] = React.useState("general");
    const { isOpen, onToggle, onClose } = useDisclosure();

    const getTeamList = async() => {
        const response = await axios.get('/api/v1/team/optionlist');
        if (!teamId) {
            setTeamId(response.data[0].id);
        }
        return response.data;
    }

    const {isLoading, isError, data, error} = useQuery(['tm-teams', teamId], getTeamList);

    function changeSection(sectionVal) {
        setSection(sectionVal);
        onClose();
    }

    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })

    const changeTeam = (teamId) => {
        setTeamId(teamId);
        setScanId(null);
        setSection('general');
    };

    const colorModeValue = useColorModeValue('sm', 'sm-dark');

    if (isLoading) return (
        <LoadingView/>
    );

    if (isError) return (
        <ErrorView message = {error}/>
    );

    if (!data) return (
        <NoDataView/>
    );

    const teamSelect = (
        <Select
            name="Team"
            colorScheme="blue"
            onChange={(ev) => changeTeam(ev.target.value)}
            value={teamId}
            placeholder="Select a Team"
            width={275}
        >
            {data.map((option) =>
                <option key={option.id} value={option.id}>{option.name}</option>
            )}
        </Select>
    );

    const scanSelect = (
        <Select
            name="Scan"
            colorScheme="blue"
            onChange={(ev) => setScanId(ev.target.value)}
            value={scanId}
            placeholder="Select a Scan"
            width={275}
        >
            <ScanOptions teamId={teamId}/>
        </Select>
    );

    return (
        <>
            <Box as="nav" bg="bg-surface" boxShadow={colorModeValue}>
                <Container>
                    <Flex
                        justify="space-between"
                        py={{
                            base: '3',
                            lg: '4',
                        }}
                    >
                        {isDesktop ? (
                            <HStack spacing="4">
                                <Image src='/apex-logo.png' h={8} />
                                {teamSelect}
                                {scanSelect}
                            </HStack>
                            ) : (
                                <Box w='100%'>
                                    <Flex>
                                        <Box w='20%'>
                                            <Image src='/apex-logo.png' h={8} />
                                        </Box>
                                        <Box w='70%'>
                                            <Flex>
                                                <Box w='100%'>{teamSelect}</Box>
                                            </Flex>
                                            <Flex>
                                                <Box w='100%'>{scanSelect}</Box>
                                            </Flex>
                                        </Box>
                                        <Box w='10%' align='right'>
                                            <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
                                        </Box>
                                    </Flex>
                                </Box>
                        )}
                        {isDesktop && (
                            <HStack spacing="4">
                                <Hide>
                                <ButtonGroup variant="ghost" spacing="1">
                                    <IconButton icon={<FiSettings fontSize="1.25rem" />} aria-label="Settings" />
                                    <IconButton icon={<FiHelpCircle fontSize="1.25rem" />} aria-label="Help Center" />
                                </ButtonGroup>
                                </Hide>
                            </HStack>
                        )}
                    </Flex>
                </Container>
                {isDesktop ? (
                    <>
                        <Divider />
                        <Container py="3">
                            <Flex justify="space-between">
                                {scanId ?
                                    <ButtonGroup variant="ghost" spacing="1">
                                        <Button aria-current={(section === 'general') ? 'page' : ''}
                                                onClick={() => changeSection('general')}>General</Button>
                                        <Button aria-current={(section === 'process') ? 'page' : ''}
                                                onClick={() => changeSection('process')}>Process Metrics</Button>
                                        <Button aria-current={(section === 'time') ? 'page' : ''}
                                                onClick={() => changeSection('time')}>Time Metrics</Button>
                                        <Button aria-current={(section === 'code') ? 'page' : ''}
                                                onClick={() => changeSection('code')}>Code Analysis</Button>
                                        <Button aria-current={(section === 'developer') ? 'page' : ''}
                                                onClick={() => changeSection('developer')}>Developer
                                            Contribution</Button>
                                        <Button aria-current={(section === 'commentary') ? 'page' : ''}
                                                onClick={() => changeSection('commentary')}>Commentary</Button>
                                    </ButtonGroup> : <></>
                                }
                            </Flex>
                        </Container>
                    </>
                ) : (
                    <>
                        <Drawer
                            isOpen={isOpen}
                            placement="left"
                            onClose={onClose}
                            isFullHeight
                            preserveScrollBarGap
                            trapFocus={false}
                        >
                            <DrawerOverlay />
                            <DrawerContent>
                                <Stack spacing="1">
                                    <NavButton label="General" onClick={() => changeSection('general')} aria-current={(section === 'general') ? 'page' : ''} icon={FiHome} />
                                    <NavButton label="Process"  onClick={() => changeSection('process')}icon={FiRepeat} aria-current={(section === 'process') ? 'page' : ''} />
                                    <NavButton label="Time Metrics" onClick={() => changeSection('time')} icon={FiWatch} aria-current={(section === 'time') ? 'page' : ''} />
                                    <NavButton label="Code Metrics" onClick={() => changeSection('code')} icon={FiEdit} aria-current={(section === 'code') ? 'page' : ''} />
                                    <NavButton label="Dev Metrics" onClick={() => changeSection('developer')} icon={FiUsers} aria-current={(section === 'developer') ? 'page' : ''} />
                                    <NavButton label="Commentary" onClick={() => changeSection('commentary')} icon={FiFilePlus} aria-current={(section === 'commentary') ? 'page' : ''} />
                                </Stack>
                            </DrawerContent>
                        </Drawer>
                    </>
                )}
            </Box>
            {!scanId ? (
                <Container py="8" flex="1">
                    <Stack spacing={{ base: '4', lg: '6' }}>
                        <Card bg='whitesmoke' padding='10px'>
                            <Box align='center' minH={90}>
                                Select a scan to continue
                            </Box>
                        </Card>
                    </Stack>
                </Container>
            ) : (
                <>

            {section === 'general' && <GeneralInfo scanId={scanId}/>}
            {section === 'process' && <ProcessMetrics scanId={scanId}/>}
            {section === 'time' && <TimeMetrics scanId={scanId}/>}
            {section === 'code' && <CodeMetrics scanId={scanId}/>}
            {section === 'developer' && <DeveloperMetrics scanId={scanId}/>}
            {section === 'commentary' && <CommentaryView scanId={scanId}/>}
                    </>
                )}
            </>
    )
}