import * as React from "react";
import {
    Box, Button,
    Card,
    CardBody,
    CardHeader, Container,
    Flex, Grid, GridItem,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text, useDisclosure
} from "@chakra-ui/react";
import {genDiffIcon, genDiffIconNoColor} from "../util/diffIconFunctions";
import {DeveloperMetricLineChart} from "./DeveloperMetricLineChart";
import {CommitView} from "./CommitView";
import {CommentView} from "./CommentView";
import {MetricLineChart} from "../shared/MetricLineChart";

export const DeveloperCard = ({scanId, devData}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [metricName, setMetricName] = React.useState("COMMIT_COUNT");
    const [metricLabel, setMetricLabel] = React.useState("Commit Count");
    const [group, setGroup] = React.useState(null);

    const [modalTitle, setModalTitle] = React.useState(null);
    const [modalBody, setModalBody] = React.useState(null);

    const updateChart = (metricName, metricLabel, group = null) => {
        if (group) {
            metricLabel = metricLabel + "  (" + group +")";
        }
        setMetricName(metricName);
        setMetricLabel(metricLabel);
        setGroup(group);
    }

    const openModal = (type, index) => {

        let suffix = index ? ' (' + devData.groupData[index].group + ')' : '';
        if (type === 'commits') {
            setModalTitle('Commits' + suffix);
            setModalBody((<CommitView commits={devData.groupData[index].commits}/>));
        } else if (type === 'comments') {
            setModalTitle('Comments' + suffix);
            setModalBody((<CommentView comments={devData.groupData[index].comments}/>));
        } else {
            return;
        }
        onOpen();
    }

    if (!devData || !scanId) {
        return (
          <></>
        );
    }

    return (
        <Container py="1" flex="1">
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap="6">
                <GridItem>
                    <Card bg='whitesmoke' h='100%'>
                        <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>{devData.developer.lastName}, {devData.developer.firstName}</CardHeader>
                        <CardBody fontSize='sm'>
                            <Flex>
                                <Box w='100%'>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMIT_COUNT', "Commit Count")}><a href='#'>Commits</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.commitCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(devData.commitCount, devData.prevCommitCount)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMENT_COUNT', "Comment Count")}><a href='#'>Comments</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.commentCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(devData.commentCount, devData.prevCommentCount)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMIT_FILES_CHANGED', "Files Touched")}><a href='#'>Files Touched</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.filesTouched}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(devData.filesTouched, devData.prevFilesTouched)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMIT_ADDITIONS', "Lines Added")}><a href='#'>Lines Added</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.linesAdded}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(devData.linesAdded, devData.prevLinesAdded)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMIT_DELETIONS', "Lines Removed")}><a href='#'>Lines Removed</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.linesDeleted}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(devData.linesDeleted, devData.prevLinesDeleted)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='50%' onClick={() => updateChart('COMMIT_CHANGES', "Lines Changed")}><a href='#'>Lines Changed</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{devData.linesChanged}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(devData.linesChanged, devData.prevLinesChanged)}</Box>
                              <Box w='20%'>&nbsp;</Box>
                          </Flex>
                          {devData.groupData.map((groupData, index) => <React.Fragment key={index}>
                              <Flex h='6'>
                                  <Box w='90%'><hr/></Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='90%'><b>{groupData.group}</b>&nbsp;&nbsp;({groupData.system})</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMIT_COUNT', "Commit Count", groupData.group)}><a href='#'>Commits</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.commitCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.commitCount, groupData.prevCommitCount)}</Box>
                                  <Box w='20%'>&nbsp;
                                      {groupData.commitCount > 0 ? <a href="#" onClick={() => openModal('commits', index)}><Text as='u'>View</Text></a> : <></>}
                                  </Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMENT_COUNT', "Comment Count", groupData.group)}><a href='#'>Comments</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.commentCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.commentCount, groupData.prevCommentCount)}</Box>
                                  <Box w='20%'>&nbsp;
                                      {groupData.commentCount > 0 ? <a href="#" onClick={() => openModal('comments', index)}><Text as='u'>View</Text></a> : <></>}
                                  </Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMIT_FILES_CHANGED', "Files Touched", groupData.group)}><a href='#'>Files Touched</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.filesTouched}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(groupData.filesTouched, groupData.prevFilesTouched)}</Box>
                                  <Box w='20%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMIT_ADDITIONS', "Lines Added", groupData.group)}><a href='#'>Lines Added</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.linesAdded}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(groupData.linesAdded, groupData.prevLinesAdded)}</Box>
                                  <Box w='20%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMIT_DELETIONS', "Lines Removed", groupData.group)}><a href='#'>Lines Removed</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.linesDeleted}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(groupData.linesDeleted, groupData.prevLinesDeleted)}</Box>
                                  <Box w='20%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMIT_CHANGES', "Lines Changed", groupData.group)}><a href='#'>Lines Changed</a></Box><Spacer />
                                  <Box w='20%' textAlign='right' paddingRight={4}>{groupData.linesChanged}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(groupData.linesChanged, groupData.prevLinesChanged)}</Box>
                                  <Box w='20%'>&nbsp;</Box>
                              </Flex>
                          </React.Fragment>)}
                                </Box>
                            </Flex>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem colSpan={2}>
                    <Card bg='whitesmoke' alignItems='center' h='100%'>
                        <Box w='95%'>
                            <MetricLineChart scanId={scanId} metric={metricName} label={metricLabel} numDataPoints={10} contributor={devData.developer.id} group={group}/>
                        </Box>
                    </Card>
                </GridItem>
            </Grid>

          <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='2xl'>
              <ModalOverlay/>
              <ModalContent>
                  <ModalHeader>{modalTitle}</ModalHeader>
                  <ModalCloseButton/>
                  <ModalBody>{modalBody}</ModalBody>
                  <ModalFooter>
                      <Button colorScheme='blue' mr={3} onClick={onClose}>Close</Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
        </Container>
    );

}