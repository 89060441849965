import {
    Badge, Box,
    Button,
    Card,
    CardBody,
    CardHeader, Container, Flex, Grid, GridItem,
    Heading,
    HStack, Icon, ListItem, Show,
    SimpleGrid, Spacer, Spinner,
    Stack, Stat, StatArrow, StatGroup, StatHelpText,
    Text, UnorderedList,
    useBreakpointValue
} from "@chakra-ui/react";
import {FcProcess, FcEditImage, FcCollaboration, FcConferenceCall} from 'react-icons/fc'
import * as React from "react";
import axios from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {genDiffIcon, genDiffIconNoColor} from "../util/diffIconFunctions";
import {AllDataChart, MetricLineChart} from "../shared/MetricLineChart";
import {ErrorView} from "../shared/ErrorView";
import {NoDataView} from "../shared/NoDataView";
import {LoadingView} from "../shared/LoadingView";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useState} from "react";
import {useFormik} from "formik";
import {commentaryValidationSchema} from "./commentaryValidationSchema";
import parse from "html-react-parser";
import { FaRegTimesCircle, FaEdit } from "react-icons/fa";
import {CommentaryCard} from "./CommentaryCard";

export const CommentaryView = ({scanId}) => {

    const [commentText, setCommentText] = useState('');

    const queryClient = useQueryClient();

    const getScanComments = async() => {
        const response = await axios.get(`/api/v1/scan/${scanId}/comments`);
        setCommentText('');
        return response.data;
    }

    const {isLoading, isError, data, error, refetch} = useQuery(['scan-commentary', scanId], getScanComments);

    const createUrl = `/api/v1/scan/${scanId}/comments`;
    const mutation = useMutation(commentData => {return axios.post(createUrl, commentData)});

    if (isLoading) return (
        <LoadingView/>
    );

    if (isError) return (
        <ErrorView message = {error}/>
    );

    if (!data) return (
        <NoDataView/>
    );

    const saveComment = () => {
        mutation.mutate({text: commentText}, {
            onSuccess: () => {
                queryClient.invalidateQueries('scan-commentary');
                setCommentText('');
            },
            onError: (error) => {
                console.log(error);
            }
        });
    }

    return (
        <Container py="8" flex="1">
                <Stack spacing={{ base: '4', lg: '6' }}>
                    <Card bg='whitesmoke' padding='10px'>
                        <Box align='center'>
                            <Box w='100%' align='left' h='200%'>
                                <ReactQuill theme="snow" value={commentText} onChange={setCommentText}/>
                                <Box align='right' paddingTop='10px'>
                                    <Button colorScheme='facebook' margin='5px' onClick={()=>saveComment()}>Save</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                    <Card bg='whitesmoke' padding='10px'>
                        <Box align='center' minH={90}>
                            {data && data.map((comment, index) =>
                                <CommentaryCard comment={comment} scanId={scanId} key={index}/>
                            )}
                            {!data || data.length === 0 ? <>No Comments Yet!</> : <></>}
                        </Box>
                    </Card>
                </Stack>
        </Container>
    );
}