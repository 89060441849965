import Chart from 'chart.js/auto'
import * as React from "react";
import {Line} from "react-chartjs-2";
import axios from "axios";
import {useQuery} from "react-query";

const options = {
    plugins: {
        title: {
            display: false
        }
    }
};

export const MetricLineChart = ({scanId, metric, label, group, state, statsType, contributor, numDataPoints}) => {

    let url = `/api/v1/data/line/${scanId}?metric=${metric}&count=${numDataPoints}`;
    if (group) {
        url = url + `&group=${group}`;
    }
    if (state) {
        url = url + `&state=${state}`;
    }
    if (statsType) {
        url = url + `&statsType=${statsType}`;
    }
    if (contributor) {
        url = url + `&contributor=${contributor}`;
    }

    const getMetricData = async() => {
        const response = await axios.get(url);
        return response.data;
    }

    const {isLoading, isError, data, error, refetch} = useQuery(['metric-line-data', scanId, metric, numDataPoints, group, state, statsType, contributor], getMetricData);

    if (isLoading) return (
        <>
            Loading...
        </>
    );

    if (isError) return (
        <>
            Error...
        </>
    );

    if (!data) return (
        <>
            No Data.
        </>
    );

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: label,
                data: data.data,
                backgroundColor: '#3338FF'
            }
        ]
    };

    return (
      <Line data={chartData} options={options} />
    );
}