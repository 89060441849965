import * as React from "react";
import {
    Box, Button,
    Card,
    CardBody,
    CardHeader, Container,
    Flex, Grid, GridItem,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text, useDisclosure
} from "@chakra-ui/react";
import {genDiffIcon, genDiffIconNoColor} from "../util/diffIconFunctions";
import {DeveloperMetricLineChart} from "../developer/DeveloperMetricLineChart";
import {CommitView} from "../developer/CommitView";
import {CommentView} from "../developer/CommentView";
import {MetricLineChart} from "../shared/MetricLineChart";
import {ProjectIssueView} from "./ProjectIssueView";

export const ProcessCard = ({scanId, groupData}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [metricName, setMetricName] = React.useState("DEFECT_OPENED_COUNT");
    const [metricLabel, setMetricLabel] = React.useState("New Defects");

    const [modalTitle, setModalTitle] = React.useState(null);
    const [modalBody, setModalBody] = React.useState(null);

    const updateChart = (metricName, metricLabel) => {
        setMetricName(metricName);
        setMetricLabel(metricLabel);
    }

    const openModal = (title, data) => {
        setModalTitle(title);
        setModalBody((<ProjectIssueView issues={data}/>));
        onOpen();
    }

    if (!groupData || !scanId) {
        return (
          <></>
        );
    }

    return (
        <Container py="1" flex="1">
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap="6">
                <GridItem>
                    <Card bg='whitesmoke' h='100%'>
                        <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>{groupData.group}</CardHeader>
                        <CardBody fontSize='sm'>
                            <Flex>
                                <Box w='100%'>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('STORY_COMPLETED_COUNT', "Stories Completed")}><a href='#'>Stories Completed</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.storyCompletedCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.storyCompletedCount, groupData.prevStoryCompletedCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.storyCompletedCount > 0 ? <a href="#" onClick={() => openModal('Stories Completed', groupData.closedStories)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('STORY_OPENED_COUNT', "New Stories")}><a href='#'>New Stories</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.storyOpenedCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.storyOpenedCount, groupData.prevStoryOpenedCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.storyOpenedCount > 0 ? <a href="#" onClick={() => openModal('New Stories', groupData.openedStories)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('STORY_OLD_COUNT', "Old Stories")}><a href='#'>Old Stories</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.oldStoryCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.oldStoryCount, groupData.prevOldStoryCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.oldStoryCount > 0 ? <a href="#" onClick={() => openModal('Old Stories', groupData.oldStories)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='90%'><hr/></Box><Spacer />
                              <Box w='10%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('DEFECT_COMPLETED_COUNT', "Defects Closed")}><a href='#'>Defects Closed</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.defectCompletedCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.defectCompletedCount, groupData.prevDefectCompletedCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.defectCompletedCount > 0 ? <a href="#" onClick={() => openModal('Defects Closed', groupData.closedDefects)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('DEFECT_OPENED_COUNT', "New Defects")}><a href='#'>New Defects</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.defectOpenedCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.defectOpenedCount, groupData.prevDefectOpenedCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.defectOpenedCount > 0 ? <a href="#" onClick={() => openModal('New Defects', groupData.openedDefects)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('DEFECT_OLD_COUNT', "Old Defects")}><a href='#'>Old Defects</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.oldDefectCount}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.oldDefectCount, groupData.prevOldDefectCount)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.oldDefectCount > 0 ? <a href="#" onClick={() => openModal('Old Defects', groupData.oldDefects)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='90%'><hr/></Box><Spacer />
                              <Box w='10%'>&nbsp;</Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='40%' onClick={() => updateChart('BACKLOG_SIZE', "Backlog Size")}><a href='#'>Backlog Size</a></Box><Spacer />
                              <Box w='20%' textAlign='right' paddingRight={4}>{groupData.backlogSize}</Box><Spacer />
                              <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.backlogSize, groupData.prevBacklogSize)}</Box>
                              <Box w='20%' textAlign='right'>&nbsp;
                                  {groupData.backlogSize > 0 ? <a href="#" onClick={() => openModal('Backlog', groupData.backlog)}><Text as='u'>View</Text></a> : <></>}
                              </Box>
                          </Flex>
                          <Flex h='6'>
                              <Box w='90%'><hr/></Box><Spacer />
                              <Box w='10%'>&nbsp;</Box>
                          </Flex>
                                </Box>
                            </Flex>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem colSpan={2}>
                    <Card bg='whitesmoke' alignItems='center' h='100%'>
                        <Box w='95%'>
                            <MetricLineChart scanId={scanId} metric={metricName} label={metricLabel} numDataPoints={10} group={groupData.group}/>
                        </Box>
                    </Card>
                </GridItem>
            </Grid>
          <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='2xl'>
              <ModalOverlay/>
              <ModalContent>
                  <ModalHeader>{modalTitle}</ModalHeader>
                  <ModalCloseButton/>
                  <ModalBody>{modalBody}</ModalBody>
                  <ModalFooter>
                      <Button colorScheme='blue' mr={3} onClick={onClose}>Close</Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
        </Container>
    );

}