import {
    Box,
    Card,
    CardBody,
    CardHeader, Container, Flex, Grid, GridItem,
    Icon, ListItem,
    SimpleGrid, Spacer,
    Stack, UnorderedList
} from "@chakra-ui/react";
import {FcProcess, FcEditImage, FcCollaboration, FcConferenceCall} from 'react-icons/fc'
import * as React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import {genDiffIcon, genDiffIconNoColor} from "./util/diffIconFunctions";
import {MetricLineChart} from "./shared/MetricLineChart";
import {ErrorView} from "./shared/ErrorView";
import {NoDataView} from "./shared/NoDataView";
import {LoadingView} from "./shared/LoadingView";
import {roundPercentage} from "./util/util";


export const GeneralInfo = ({scanId}) => {

    const [metricName, setMetricName] = React.useState("LINES_OF_CODE");
    const [metricLabel, setMetricLabel] = React.useState("Lines of Code");

    const updateChart = (metricName, metricLabel) => {
        setMetricName(metricName);
        setMetricLabel(metricLabel);
    }

    const getGeneralScanInfo = async() => {
        const response = await axios.get(`/api/v1/scan/general/${scanId}`);
        setMetricName("LINES_OF_CODE");
        setMetricLabel("Lines of Code");
        return response.data;
    }

    const {isLoading, isError, data, error} = useQuery(['scan-general', scanId], getGeneralScanInfo);

    if (isLoading) return (
        <LoadingView/>
    );

    if (isError) return (
        <ErrorView message = {error}/>
    );

    if (!data) return (
        <NoDataView/>
    );

    return (
        <Container py="8" flex="1">
                <Stack spacing={{ base: '4', lg: '6' }}>
                    <SimpleGrid columns={{ base: 1, lg: 3 }} gap="6">
                        <Card bg='whitesmoke'>
                            <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>Process&nbsp;&nbsp;&nbsp;<Icon as={FcProcess} verticalAlign='middle'/></CardHeader>
                            <CardBody fontSize='sm'>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('BACKLOG_SIZE', "Backlog Size")}><a href='#'>Backlog Size</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.backlogSize}</Box><Spacer />
                                    <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(data.backlogSize, data.prevBacklogSize)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('STORY_COMPLETED_COUNT', "Stories Completed")}><a href='#'>Stories Completed</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.completedStoryCount}</Box><Spacer />
                                    <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(data.completedStoryCount, data.prevCompletedStoryCount)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('DEFECT_OPENED_COUNT', "New Defects")}><a href='#'>New Defects</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.openedDefectCount}</Box><Spacer />
                                    <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(data.openedDefectCount, data.prevOpenedDefectCount, true)}</Box>
                                </Flex>
                            </CardBody>
                        </Card>
                        <Card bg='whitesmoke'>
                            <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>Code&nbsp;&nbsp;&nbsp;<Icon as={FcEditImage} verticalAlign='middle'/></CardHeader>
                            <CardBody fontSize='sm'>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('LINES_OF_CODE', "Lines of Code")}><a href='#'>Lines of Code</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.totalLinesOfCode}</Box><Spacer />
                                    <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIconNoColor(data.totalLinesOfCode, data.prevTotalLinesOfCode)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('BLOCKER_ISSUE_COUNT', "Blocker Issues")}><a href='#'>Blocker Issues</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.numBlockerIssues}</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.numBlockerIssues, data.prevNumBlockerIssues, true)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('CRITICAL_ISSUE_COUNT', "Critical Issues")}><a href='#'>Critical Issues</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.numCriticalIssues}</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.numCriticalIssues, data.prevNumCriticalIssues, true)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('TEST_COUNT', "Test Count")}><a href='#'>Test Count</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.testCount}</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.testCount, data.prevTestCount)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('TEST_COVERAGE', "Minimum Test Coverage")}><a href='#'>Min Test Coverage</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{roundPercentage(data.lowestTestCoverage, true)}%</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.lowestTestCoverage, data.prevLowestTestCoverage)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('BUILD_TIME_MINS', "Maximum Build Time")}><a href='#'>Max Build Time</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.slowestBuildTimeSeconds}</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.slowestBuildTimeMinutes, data.prevSlowestBuildTimeMinutes, true)}</Box>
                                </Flex>
                            </CardBody>
                        </Card>
                        <Card bg='whitesmoke'>
                            <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>Team&nbsp;&nbsp;&nbsp;<Icon as={FcCollaboration} verticalAlign='middle'/></CardHeader>
                            <CardBody fontSize='sm'>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('COMMIT_COUNT', "Commits")}><a href='#'>Commits</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.numPushedCommits}</Box><Spacer />
                                    <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(data.numPushedCommits, data.prevNumPushedCommits)}</Box>
                                </Flex>
                                <Flex h='6'>
                                    <Box w='45%' onClick={() => updateChart('COMMENT_COUNT', "Comments")}><a href='#'>Review Comments</a></Box><Spacer />
                                    <Box w='30%' textAlign='right' paddingRight={4}>{data.totalReviewCommentCount}</Box><Spacer />
                                    <Box w='20%' paddingTop='2px'>{genDiffIcon(data.totalReviewCommentCount, data.prevTotalReviewCommentCount)}</Box>
                                </Flex>
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap="6">
                        <GridItem>
                                <Card bg='whitesmoke' h='100%'>
                                    <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>Team Contributors&nbsp;&nbsp;&nbsp;<Icon as={FcConferenceCall} verticalAlign='middle'/></CardHeader>
                                    <CardBody fontSize='medium'>
                                        <UnorderedList fontSize='sm'>
                                            {data.contributors.map((contributor) =>
                                                <ListItem key={contributor.email} h='6'>{contributor.lastName}, {contributor.firstName}</ListItem>
                                            )}
                                        </UnorderedList>
                                    </CardBody>
                                </Card>
                        </GridItem>
                        <GridItem colSpan={2} h='100%'>
                                <Card bg='whitesmoke' alignItems='center'>
                                    <Box w='95%'>
                                        <MetricLineChart scanId={scanId} metric={metricName} label={metricLabel} numDataPoints={10}/>
                                    </Box>
                                </Card>
                        </GridItem>
                    </Grid>
                </Stack>
        </Container>
    );
}