import * as React from "react";
import {Box, Card, CardBody, Flex, Spacer} from "@chakra-ui/react";

export const CommentView = ({comments}) => {

    return (
      <>
          {comments && comments.map((comment, index) =>
              <React.Fragment key = {index}>
                  <Card bg='blue.50' marginTop = '3px' marginBottom = '10px' paddingBottom = '5px'>
                      <CardBody padding = '3px' paddingLeft = '5px'>
                          <Flex marginTop='5px'>
                              <Box w='7%' fontWeight='bold' fontSize='xs'>Time</Box>
                              <Box w='93%' fontSize='xs'><a target='_blank' href={comment.link} rel='noreferrer'><u>{comment.time}</u></a></Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='100%' fontSize='xs'>{comment.body}</Box>
                          </Flex>
                      </CardBody>
                  </Card>
              </React.Fragment>
          )}
      </>
    );
}