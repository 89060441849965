import * as React from "react";
import {Box, Card, CardBody, Flex, Spacer} from "@chakra-ui/react";

export const ProjectIssueView = ({issues}) => {

    return (
      <>
          {issues && issues.map((issue) =>
              <React.Fragment key = {issue.sourceSystemId}>
                  <Card bg='blue.50' marginTop = '3px' marginBottom = '10px' paddingBottom = '5px'>
                      <CardBody padding = '3px' paddingLeft = '5px'>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Source ID</Box>
                              <Box w='80%' fontSize='xs'>{issue.sourceSystemId}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Code</Box>
                              <Box w='80%' fontSize='xs'><a target='_blank' href={issue.link} rel='noreferrer'><u>{issue.sourceSystemCode}</u></a></Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Latest Status</Box>
                              <Box w='80%' fontSize='xs'>{issue.latestStatus}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Description</Box>
                              <Box w='80%' fontSize='xs'>{issue.description}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Created</Box>
                              <Box w='80%' fontSize='xs'>{issue.issueCreationDate}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Last Updated</Box>
                              <Box w='80%' fontSize='xs'>{issue.issueLastUpdateDate}</Box>
                          </Flex>
                      </CardBody>
                  </Card>
              </React.Fragment>
          )}
      </>
    );
}