import * as React from "react";
import {Card, Container, Spinner} from "@chakra-ui/react";

export const LoadingView = () => {

    return (
        <Container py="8" flex="1" align='center'>
            <Card bg='blue.50' paddingTop='50px' paddingBottom='50px'  align='center'>
                <Spinner/>
            </Card>
        </Container>
    );
}