import * as React from "react";
import {
    Box, Button,
    Card,
    CardBody,
    CardHeader, Container,
    Flex, Grid, GridItem, Link,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer, Stack,
    Text, useDisclosure
} from "@chakra-ui/react";
import {genDiffIcon, genDiffIconNoColor} from "../util/diffIconFunctions";
import {DeveloperMetricLineChart} from "../developer/DeveloperMetricLineChart";
import {CommitView} from "../developer/CommitView";
import {CommentView} from "../developer/CommentView";
import {MetricLineChart} from "../shared/MetricLineChart";
import {ProjectIssueView} from "../process/ProjectIssueView";
import {CodeIssueView} from "./CodeIssueView";
import {roundPercentage} from "../util/util";

export const CodeAnalysisCard = ({scanId, groupData}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [metricName, setMetricName] = React.useState("LINES_OF_CODE");
    const [metricLabel, setMetricLabel] = React.useState("Lines of Code");

    const [modalTitle] = React.useState("Code Issues");
    const [modalBody, setModalBody] = React.useState(null);

    const updateChart = (metricName, metricLabel, group) => {
        setMetricName(metricName);
        setMetricLabel(metricLabel);
    }

    const openModal = (issues) => {
        setModalBody((<CodeIssueView issues={issues}/>));
        onOpen();
    }

    if (!groupData || !scanId) {
        return (
          <></>
        );
    }

    return (
      <Container py="1" flex="1">
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap="6">
          <GridItem>
              <Card bg='whitesmoke' h='100%'>
                  <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>{groupData.group}</CardHeader>
                  <CardBody fontSize='sm'>
                      <Flex>
                          <Box w='100%'>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('LINES_OF_CODE', "Lines of Code", groupData.group)}><Link>Lines of Code</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.linesOfCode}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.linesOfCode, groupData.prevLinesOfCode, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='90%'><hr/></Box><Spacer />
                                  <Box w='10%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('BLOCKER_ISSUE_COUNT', "Blocker Issues", groupData.group)}><Link>Blocker Issues</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.blockerIssueCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.blockerIssueCount, groupData.prevBlockerIssueCount, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('CRITICAL_ISSUE_COUNT', "Critical Issues", groupData.group)}><Link>Critical Issues</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.criticalIssueCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.criticalIssueCount, groupData.prevCriticalIssueCount, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('MAJOR_ISSUE_COUNT', "Major Issues", groupData.group)}><Link>Major Issues</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.majorIssueCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.majorIssueCount, groupData.prevMajorIssueCount, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='100%' align='center'><a href="#" onClick={() => openModal(groupData.codeIssues)}><Text as='u'>Code Issues</Text></a></Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='90%'><hr/></Box><Spacer />
                                  <Box w='10%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COGNITIVE_COMPLEXITY', "Complexity", groupData.group)}><Link>Complexity</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.cognitiveComplexity}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.cognitiveComplexity, groupData.prevCognitiveComplexity, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('DUPLICATE_LINE_COUNT', "Duplicate Lines", groupData.group)}><Link>Duplicate Lines</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.duplicateLineCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.duplicateLineCount, groupData.prevDuplicateLineCount, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('DUPLICATE_LINE_DENSITY', "Duplicate Line Density", groupData.group)}><Link>Duplicate Line Density</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{roundPercentage(groupData.duplicateLineDensity, true)}%</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.duplicateLineDensity, groupData.prevDuplicateLineDensity, true)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='90%'><hr/></Box><Spacer />
                                  <Box w='10%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMENT_LINE_COUNT', "Comment Lines", groupData.group)}><Link>Comment Lines</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.commentLineCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.commentLineCount, groupData.prevCommentLineCount)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('COMMENT_LINE_DENSITY', "Comment Line Density", groupData.group)}><Link>Comment Line Density</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{roundPercentage(groupData.commentLineDensity, true)}%</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.commentLineDensity, groupData.prevCommentLineDensity)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='90%'><hr/></Box><Spacer />
                                  <Box w='10%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('TEST_COUNT', "Test Count", groupData.group)}><Link>Test Count</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{groupData.testCount}</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.testCount, groupData.prevTestCount)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                              <Flex h='6'>
                                  <Box w='50%' onClick={() => updateChart('TEST_COVERAGE', "Test Coverage", groupData.group)}><Link>Test Coverage</Link></Box><Spacer />
                                  <Box w='25%' textAlign='right' paddingRight={4}>{roundPercentage(groupData.testCoverage, true)}%</Box><Spacer />
                                  <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(groupData.testCoverage, groupData.prevTestCoverage)}</Box>
                                  <Box w='5%'>&nbsp;</Box>
                              </Flex>
                          </Box>
                      </Flex>
                  </CardBody>
              </Card>
              </GridItem>
              <GridItem colSpan={2}>
                  <Card bg='whitesmoke' alignItems='center' h='100%'>
                      <Box w='95%'>
                          <MetricLineChart scanId={scanId} metric={metricName} label={metricLabel} numDataPoints={10} group={groupData.group}/>
                      </Box>
                  </Card>
              </GridItem>
          </Grid>
          <Card bg='whitesmoke' padding='1px' marginTop='20px'>
              <CardBody fontSize='sm' padding='8px' paddingLeft='20px'>
                  <b>Projects Analyzed:</b>
                  <ul style={{paddingLeft:20}}>
                  {groupData.projectsAnalyzed && groupData.projectsAnalyzed.map((project, index) =>
                      <li key = {index}><a target='_blank' rel='noreferrer' href={'' + groupData.projectUrl + project}>{project}</a></li>
                  )}
                  </ul>
              </CardBody>
          </Card>
          <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='2xl'>
              <ModalOverlay/>
              <ModalContent>
                  <ModalHeader>{modalTitle}</ModalHeader>
                  <ModalCloseButton/>
                  <ModalBody>{modalBody}</ModalBody>
                  <ModalFooter>
                      <Button colorScheme='blue' mr={3} onClick={onClose}>Close</Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
          </Container>
    );

}