import * as React from "react";
import {Box, Card, CardBody, Flex, Spacer} from "@chakra-ui/react";

export const CodeIssueView = ({issues}) => {

    return (
      <>
          {issues && issues.map((issue) =>
              <React.Fragment key = {issue.sourceSystemKey}>
                  <Card bg='blue.50' marginTop = '3px' marginBottom = '10px' paddingBottom = '5px'>
                      <CardBody padding = '3px' paddingLeft = '5px'>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Source Key</Box>
                              <Box w='80%' fontSize='xs'><a target='_blank' href={issue.link} rel='noreferrer'><u>{issue.sourceKey}</u></a></Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Severity</Box>
                              <Box w='80%' fontSize='xs'>{issue.severity}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Code</Box>
                              <Box w='80%' fontSize='xs'>{issue.sourceCode}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Component</Box>
                              <Box w='80%' fontSize='xs'>{issue.component}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Description</Box>
                              <Box w='80%' fontSize='xs'>{issue.description}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='20%' fontWeight='bold' fontSize='xs'>Created</Box>
                              <Box w='80%' fontSize='xs'>{issue.creationDate}</Box>
                          </Flex>
                      </CardBody>
                  </Card>
              </React.Fragment>
          )}
      </>
    );
}