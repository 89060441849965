
export const formatSeconds = (time) => {
    return Math.floor(time/60) + ':' + padDigits(Math.floor(time%60))
}

export const formatMinutes = (time) => {
    if (isNaN(time)) {
        return NaN;
    }

    return Math.floor(time/24/60) + ":" + padDigits(Math.floor(time/60%24)) + ':' + padDigits(Math.floor(time%60))
}

function padDigits(value, numDigits = 2) {
    let padded = value.toString();
    while (padded.length < numDigits) {
        padded = "0" + padded;
    }
    return padded;
}

export const roundPercentage = (value, alreadyMultipled = false) => {
    if (value) {
        if (alreadyMultipled) {
            return Math.round(value * 100) / 100;
        } else {
            return Math.round((value * 100) * 100) / 100;
        }
    }
    return 0;

}