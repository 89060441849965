import {TopMenu} from "./menu/TopMenu";
import {Box} from "@chakra-ui/react";
import * as React from "react";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

export const Dashboard = () => {

    const {isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently} = useAuth0();

    const setToken = async () => {
        const token = await getAccessTokenSilently();
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }

    const performLogin = async () => {
        await loginWithRedirect();
    }

    if (!isLoading && !isAuthenticated) {
        performLogin();
        return null;
    } else {

        setToken();

        return (
            <Box
                as="section"
                pb={{
                    base: '12',
                    md: '24',
                }}
                bg='blue.700'
            >
                <TopMenu/>
            </Box>
        );
    }
}