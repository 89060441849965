import {useQuery} from "react-query";
import axios from "axios";
import * as React from "react";


export const ScanOptions = ({teamId}) => {

    const getTeamList = async() => {
        if (teamId) {
            const response = await axios.get(`/api/v1/scan/optionlist?teamId=${teamId}`);
            return response.data;
        }
    }

    const {data} = useQuery(['scanoptions', teamId], getTeamList);

    return (
        <>
        {data && data.length > 0 && data.map((option) =>
                <option key={option.id} value={option.id}>{option.name}</option>
        )}
        </>
    );

}