import {Stat, StatArrow, StatGroup, StatHelpText, Tooltip} from '@chakra-ui/react'
import * as React from "react";

const genDiffIconWithColor = (val1, val2, upColor, downColor, formatFunction) => {

    let label;
    if (val2 === undefined || isNaN(val2) || val2 === null || val1 === undefined || isNaN(val1) || val1 === null) {
        label = "Not Reported";
    } else {
        if (formatFunction) {
            label = `Previous: ${formatFunction(val2)}`;
        } else {
            label = `Previous: ${val2}`;
        }
    }

    if (val1 < val2) {
        let percentage = 100;
        if (val1 !== 0) {
            if ((val2 - val1)/val1 >= 1) {
                percentage = Math.round((val2 - val1)/val1 * 100);
            } else {;
                percentage = Math.round(((val2 - val1)/val1 * 100) * 100) / 100;
            }
        }

        return (
            <>
                <StatGroup>
                    <Stat>
                        <Tooltip label={label}>
                        <StatHelpText fontSize='2xs'>
                            <StatArrow type='decrease' color={downColor}/>
                            {val1 === 0 ? 100 : percentage}%
                        </StatHelpText>
                        </Tooltip>
                    </Stat>
                </StatGroup>
            </>
        );
    } else if (val1 > val2) {
        return (
            <>
                <StatGroup>
                    <Stat>
                        <Tooltip label={label}>
                            <StatHelpText fontSize='2xs'>
                                <StatArrow type='increase' color={upColor}/>
                                {Math.round(((val2 - val1)/val1 * -100) * 100) / 100}%
                            </StatHelpText>
                        </Tooltip>
                    </Stat>
                </StatGroup>
            </>
        );
    }
};

export const genDiffIcon = (val1, val2, reverseColor = false, formatFunction) => {

    let downIconColor = reverseColor ? "green.500" : "red.500";
    let upIconColor = reverseColor ? "red.500" : "green.500";

    return genDiffIconWithColor(val1, val2, upIconColor, downIconColor, formatFunction);
}

export const genDiffIconNoColor = (val1, val2, formatFunction) => {
    let color = "black.500";
    return genDiffIconWithColor(val1, val2, color, color, formatFunction);
}