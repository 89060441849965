import {Box, Button, Card, Flex, Icon, Spacer} from "@chakra-ui/react";
import parse from "html-react-parser";
import {FaEdit, FaRegTimesCircle} from "react-icons/fa";
import * as React from "react";
import {useState} from "react";
import ReactQuill from "react-quill";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";

export const CommentaryCard = ({scanId, comment}) => {

    const [commentText, setCommentText] = useState(comment.text);
    const [mode, setMode] = useState('view');

    const deleteUrl = `/api/v1/scan/${scanId}/comments/${comment.id}`;
    const deleteMutation = useMutation(() => {return axios.delete(deleteUrl)});

    const updateUrl = `/api/v1/scan/${scanId}/comments/${comment.id}`;
    const updateMutation = useMutation((commentData) => {return axios.put(updateUrl, commentData)});

    const queryClient = useQueryClient();

    const edit = () => {
        setMode('edit');
    }

    const remove = () => {
        deleteMutation.mutate({}, {
            onSuccess: () => {
                queryClient.invalidateQueries('scan-commentary');
            },
            onError: (error) => {
                console.log(error);
            }
        });
    }

    const updateComment = () => {

        const updatedComment = {...comment, text: commentText}

        updateMutation.mutate(updatedComment, {
            onSuccess: () => {
                queryClient.invalidateQueries('scan-commentary');
                setMode('view');
            },
            onError: (error) => {
                console.log(error);
            }
        });
    }

    const cancel = () => {
        setMode('view');
        setCommentText(comment.text);
    }

    return (
        <>
        <Card padding='20px' fontSize='sm' bg='blue.50' margin='10px' id={'comment-' + comment.id} paddingBottom='5px' paddingTop='10px'>
            {mode === 'view' ?
                <>
                <Flex marginBottom='10px'>
                    <Box w='80%' align='left' paddingLeft='5px'>{parse(comment.text)}</Box><Spacer/>
                    <Box w='20%' align='right'>
                        <a href={'#comment-' + comment.id} onClick={() => edit()}><Icon as={FaEdit} verticalAlign='top' boxSize={5}/></a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" onClick={() => remove()}><Icon as={FaRegTimesCircle} verticalAlign='top' boxSize={5}/></a>
                    </Box>
                </Flex>
                <Flex>
                    <Box w='100%' align='left'  marginBottom='0px' paddingLeft='5px' fontWeight='bold' fontSize='xs'>{comment.user}&nbsp;&nbsp;&nbsp;&nbsp;{comment.time}</Box>
                </Flex>
                </>
                :
                <>
                    <Box w='100%' align='left'><ReactQuill theme="snow" value={commentText} onChange={setCommentText} style={{backgroundColor: "whitesmoke"}}/></Box>
                    <Box align='right' paddingTop='10px'>
                        <Button colorScheme='facebook' margin='5px' onClick={()=>updateComment()}>Save</Button>
                        &nbsp;&nbsp;
                        <Button colorScheme='facebook' margin='5px' onClick={()=>cancel()}>Cancel</Button>
                    </Box>
                </>
            }
        </Card>
        </>
    );
}