import * as React from "react";
import {Box, Card, CardBody, Flex} from "@chakra-ui/react";

export const CommitView = ({commits}) => {

    return (
      <>
          {commits && commits.map((commit) =>
              <React.Fragment key = {commit.commitHash}>
                  <Card bg='blue.50' marginTop = '3px' marginBottom = '10px' paddingBottom = '5px'>
                      <CardBody padding = '3px' paddingLeft = '5px'>
                          <Flex marginTop='5px'>
                              <Box w='7%' fontWeight='bold' fontSize='xs'>Time</Box>
                              <Box w='93%' fontSize='xs'>{commit.commitTime}</Box>
                          </Flex>
                          <Flex>
                              <Box w='9%' fontWeight='bold' fontSize='xs'>Files</Box>
                              <Box w='13%' fontSize='xs'>{commit.filesTouched}</Box>
                              <Box w='10%' fontWeight='bold' fontSize='xs'>Adds</Box>
                              <Box w='13%' fontSize='xs'>{commit.linesAdded}</Box>
                              <Box w='14%' fontWeight='bold' fontSize='xs'>Deletes</Box>
                              <Box w='13%' fontSize='xs'>{commit.linesDeleted}</Box>
                              <Box w='15%' fontWeight='bold' fontSize='xs'>Changes</Box>
                              <Box w='13%' fontSize='xs'>{commit.linesChanged}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='100%' fontSize='xs'>{commit.message}</Box>
                          </Flex>
                          <Flex marginTop='5px'>
                              <Box w='10%' fontWeight='bold' fontSize='xs'>Hash</Box>
                              <Box w='90%' fontSize='xs'><a target='_blank' href={commit.link} rel='noreferrer'><u>{commit.commitHash}</u></a></Box>
                          </Flex>
                      </CardBody>
                  </Card>
              </React.Fragment>
          )}
      </>
    );
}