import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Grid,
    GridItem,
    Heading,
    Stack,
    StackDivider,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue, Container, SimpleGrid, Button
} from "@chakra-ui/react";
import * as React from "react";
import axios from "axios";
import {useQuery} from "react-query";
import {genDiffIcon} from "../util/diffIconFunctions";
import {formatMinutes} from "../util/util";
import {DeveloperCard} from "../developer/DeveloperCard";
import {ProcessCard} from "../process/ProcessCard";
import {TimeMetricsCard} from "./TimeMetricsCard";
import {LoadingView} from "../shared/LoadingView";
import {ErrorView} from "../shared/ErrorView";
import {NoDataView} from "../shared/NoDataView";

export const TimeMetrics = ({scanId}) => {

    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [groupData, setGroupData] = React.useState(null);

    const getProcessScanInfo = async() => {
        const response = await axios.get(`/api/v1/scan/process/${scanId}`);
        setSelectedGroup(null);
        setGroupData(null);
        return response.data;
    }

    const {isLoading, isError, data, error, refetch} = useQuery(['scan-time', scanId], getProcessScanInfo);

    if (isLoading) return (
        <LoadingView/>
    );

    if (isError) return (
        <ErrorView message = {error}/>
    );

    if (!data) return (
        <NoDataView/>
    );

    const isButtonActive = (group) => {
        if (group === selectedGroup) {
            return true;
        }
        return false;
    }

    const changeGroup = (data) => {
        setGroupData(data);
        setSelectedGroup(data.group);
    }

    if (data && selectedGroup === null) {
        setSelectedGroup(data.groups[0].group);
        setGroupData(data.groups[0]);
    }

    return (
        <Container py="5" alignContent='center'>
            <Box px="8">
            {data && data.groups.map((grp) =>
                <React.Fragment key = {grp.group}>
                    <Button colorScheme='whiteAlpha' variant='solid'  size='sm' margin={1} isActive={isButtonActive(grp.group)}  onClick={()=>changeGroup(grp)}>{grp.group}</Button>
                </React.Fragment>
            )}
            </Box>
            {data ? <>
                <br/>
                <TimeMetricsCard groupData={groupData} scanId={scanId}/>
            </> : <>No Data</>
            }
        </Container>
    );
}