import * as React from "react";
import {
    Box, Button,
    Card,
    CardBody,
    CardHeader, Container,
    Flex, Grid, GridItem,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text, useDisclosure
} from "@chakra-ui/react";
import {genDiffIcon, genDiffIconNoColor} from "../util/diffIconFunctions";
import {DeveloperMetricLineChart} from "../developer/DeveloperMetricLineChart";
import {CommitView} from "../developer/CommitView";
import {CommentView} from "../developer/CommentView";
import {MetricLineChart} from "../shared/MetricLineChart";
import {ProjectIssueView} from "../process/ProjectIssueView";
import {formatMinutes} from "../util/util";

export const TimeMetricsCard = ({scanId, groupData}) => {

    const [metricName, setMetricName] = React.useState("STORY_CYCLE_TIME");
    const [metricLabel, setMetricLabel] = React.useState("Story Cycle Time (Minutes)");
    const [state, setState] = React.useState(null);
    const [statsType, setStatsType] = React.useState("AVERAGE");

    const [tisMetricName, setTisMetricName] = React.useState("STORY_TIME_IN_STATE");
    const [tisMetricLabel, setTisMetricLabel] = React.useState(null);
    const [tisState, setTisState] = React.useState(null);
    const [tisStatsType, setTisStatsType] = React.useState("AVERAGE");

    const [disMetricName, setDisMetricName] = React.useState("DEFECT_TIME_IN_STATE");
    const [disMetricLabel, setDisMetricLabel] = React.useState(null);
    const [disState, setDisState] = React.useState(null);
    const [disStatsType, setDisStatsType] = React.useState("AVERAGE");

    const updateChart = (metricName, metricLabel, state, statsType) => {
        setMetricName(metricName);
        setMetricLabel(metricLabel);
        setState(state);
        setStatsType(statsType);
    }

    const updateTisChart = (metricName, metricLabel, state, statsType) => {
        setTisMetricName(metricName);
        setTisMetricLabel(metricLabel);
        setTisState(state);
        setTisStatsType(statsType);
    }

    const updateDisChart = (metricName, metricLabel, state, statsType) => {
        setDisMetricName(metricName);
        setDisMetricLabel(metricLabel);
        setDisState(state);
        setDisStatsType(statsType);
    }

    if (!groupData || !scanId) {
        return (
          <></>
        );
    }

    const timeMetrics = groupData.timeMetrics;
    const storyCycleTime = timeMetrics.storyCycleTimeData;
    const defectCycleTime = timeMetrics.defectCycleTimeData;
    const storyTIS = timeMetrics.storyTimeInStateData;
    const defectTIS = timeMetrics.defectTimeInStateData;

    return (
        <Container py="1" flex="1">
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap="6">
                <GridItem>
                      <Card bg='whitesmoke' h='100%'>
                          <CardHeader fontWeight="bold" fontSize="large" paddingBottom={0}>{groupData.group}</CardHeader>
                          <CardBody fontSize='sm'>
                              <Flex>
                                  <Box w='100%'>
                                      <Flex h='6'>
                                          <Box w='90%'><b>Story Cycle Time</b>&nbsp;&nbsp;(days:hrs:min)</Box><Spacer />
                                          <Box w='10%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('STORY_CYCLE_TIME', "Average Story Cycle Time (Minutes)", null, 'AVERAGE')}><a href='#'>&nbsp;&nbsp;Average</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(storyCycleTime.averageMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(storyCycleTime.averageMinutes, storyCycleTime.prevAverageMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('STORY_CYCLE_TIME', "Max Story Cycle Time (Minutes)", null, 'MAX')}><a href='#'>&nbsp;&nbsp;Maximum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(storyCycleTime.maxMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(storyCycleTime.maxMinutes, storyCycleTime.prevMaxMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('STORY_CYCLE_TIME', "Min Story Cycle Time (Minutes)", null, 'MIN')}><a href='#'>&nbsp;&nbsp;Minimum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(storyCycleTime.minMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(storyCycleTime.minMinutes, storyCycleTime.prevMinMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('STORY_CYCLE_TIME', "Median Story Cycle Time (Minutes)", null, 'MEDIAN')}><a href='#'>&nbsp;&nbsp;Median</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(storyCycleTime.medianMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(storyCycleTime.medianMinutes, storyCycleTime.prevMedianMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('STORY_CYCLE_TIME', "Story Cycle Time Std Deviation (Minutes)", null, 'STANDARD_DEVIATION')}><a href='#'>&nbsp;&nbsp;Std Deviation</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(storyCycleTime.stdDevMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(storyCycleTime.stdDevMinutes, storyCycleTime.prevStdDevMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='90%'><hr/></Box><Spacer />
                                          <Box w='10%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='90%'><b>Defect Cycle Time</b>&nbsp;&nbsp;(days:hrs:min)</Box><Spacer />
                                          <Box w='10%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('DEFECT_CYCLE_TIME', "Average Defect Cycle Time (Minutes)", null, 'AVERAGE')}><a href='#'>&nbsp;&nbsp;Average</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(defectCycleTime.averageMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(defectCycleTime.averageMinutes, defectCycleTime.prevAverageMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('DEFECT_CYCLE_TIME', "Max Defect Cycle Time (Minutes)", null, 'MAX')}><a href='#'>&nbsp;&nbsp;Maximum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(defectCycleTime.maxMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(defectCycleTime.maxMinutes, defectCycleTime.prevMaxMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('DEFECT_CYCLE_TIME', "Min Defect Cycle Time (Minutes)", null, 'MIN')}><a href='#'>&nbsp;&nbsp;Minimum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(defectCycleTime.minMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(defectCycleTime.minMinutes, defectCycleTime.prevMinMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('DEFECT_CYCLE_TIME', "Median Defect Cycle Time (Minutes)", null, 'MEDIAN')}><a href='#'>&nbsp;&nbsp;Median</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(defectCycleTime.medianMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(defectCycleTime.medianMinutes, defectCycleTime.prevMedianMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateChart('DEFECT_CYCLE_TIME', "Defect Cycle Time Std Deviation (Minutes)", null, 'STANDARD_DEVIATION')}><a href='#'>&nbsp;&nbsp;Std Deviation</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(defectCycleTime.stdDevMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(defectCycleTime.stdDevMinutes, defectCycleTime.prevStdDevMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                  </Box>
                              </Flex>
                          </CardBody>
                      </Card>
                </GridItem>
                <GridItem colSpan={2}>
                    <Box w='95%' h='100%'>
                        <Card bg='whitesmoke' alignItems='center' h='100%'>
                            <MetricLineChart scanId={scanId} metric={metricName} label={metricLabel} numDataPoints={10} group={groupData.group} state={state} statsType={statsType}/>
                        </Card>
                    </Box>
                </GridItem>
                <GridItem>
                  <Card bg='whitesmoke' h='100%'>
                      <CardBody fontSize='sm'>
                          <Flex>
                              <Box w='100%'>
                                  <Flex h='6'>
                                      <Box w='90%'><b>Time In State - Stories</b></Box><Spacer />
                                      <Box w='10%'>&nbsp;</Box>
                                  </Flex>
                                { storyTIS && storyTIS.map((stateData) => (
                                  <React.Fragment key = {stateData.state}>
                                      <Flex h='6'>
                                          <Box w='50%'>&nbsp;&nbsp;State</Box><Spacer />
                                          <Box w='45%' textAlign='left'><b>{stateData.state}</b></Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateTisChart('STORY_TIME_IN_STATE', `Average Story Time In State - ${stateData.state} (Minutes)`, stateData.state, 'AVERAGE')}><a href='#tischart'>&nbsp;&nbsp;Average</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.averageMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.averageMinutes, stateData.prevAverageMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateTisChart('STORY_TIME_IN_STATE', `Max Story Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MAX')}><a href='#tischart'>&nbsp;&nbsp;Maximum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.maxMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.maxMinutes, stateData.prevMaxMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateTisChart('STORY_TIME_IN_STATE', `Min Story Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MIN')}><a href='#tischart'>&nbsp;&nbsp;Minimum</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.minMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.minMinutes, stateData.prevMinMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateTisChart('STORY_TIME_IN_STATE', `Median Story Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MEDIAN')}><a href='#tischart'>&nbsp;&nbsp;Median</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.medianMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.medianMinutes, stateData.prevMedianMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='6'>
                                          <Box w='50%' onClick={() => updateTisChart('STORY_TIME_IN_STATE', `Story Time In State Std Deviation - ${stateData.state} (Minutes)`, stateData.state, 'STANDARD_DEVIATION')}><a href='#tischart'>&nbsp;&nbsp;Std Deviation</a></Box><Spacer />
                                          <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.stdDevMinutes)}</Box><Spacer />
                                          <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.stdDevMinutes, stateData.prevStdDevMinutes, true, formatMinutes)}</Box>
                                          <Box w='5%'>&nbsp;</Box>
                                      </Flex>
                                      <Flex h='2'>
                                          <Box w='100%'>&nbsp;</Box><Spacer />
                                      </Flex>
                                  </React.Fragment>
                                ))}
                              </Box>
                          </Flex>
                      </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={2}>
                    <Box w='95%' h='100%'>
                        <Card bg='whitesmoke' alignItems='center' h='100%'>
                            {tisState ?
                                <MetricLineChart id="tischart" scanId={scanId} metric={tisMetricName} label={tisMetricLabel} numDataPoints={10} group={groupData.group} state={tisState} statsType={tisStatsType}/>
                                :
                                <Text paddingTop='100px'>Select a Story Time In State Metric</Text>}
                        </Card>
                    </Box>
                </GridItem>
                <GridItem>
                    <Card bg='whitesmoke' h='100%'>
                        <CardBody fontSize='sm'>
                            <Flex>
                                <Box w='100%'>
                                  <Flex h='6'>
                                      <Box w='90%'><b>Time In State - Defects</b></Box><Spacer />
                                      <Box w='10%'>&nbsp;</Box>
                                  </Flex>
                          { defectTIS && defectTIS.map((stateData) => (
                              <React.Fragment key = {stateData.state}>
                                  <Flex h='6'>
                                      <Box w='50%'>&nbsp;&nbsp;State</Box><Spacer />
                                      <Box w='45%' textAlign='left'><b>{stateData.state}</b></Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='6'>
                                      <Box w='50%' onClick={() => updateDisChart('DEFECT_TIME_IN_STATE', `Average Defect Time In State - ${stateData.state} (Minutes)`, stateData.state, 'AVERAGE')}><a href='#dischart'>&nbsp;&nbsp;Average</a></Box><Spacer />
                                      <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.averageMinutes)}</Box><Spacer />
                                      <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.averageMinutes, stateData.prevAverageMinutes, true, formatMinutes)}</Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='6'>
                                      <Box w='50%' onClick={() => updateDisChart('DEFECT_TIME_IN_STATE', `Max Defect Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MAX')}><a href='#dischart'>&nbsp;&nbsp;Maximum</a></Box><Spacer />
                                      <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.maxMinutes)}</Box><Spacer />
                                      <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.maxMinutes, stateData.prevMaxMinutes, true, formatMinutes)}</Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='6'>
                                      <Box w='50%' onClick={() => updateDisChart('DEFECT_TIME_IN_STATE', `Min Defect Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MIN')}><a href='#dischart'>&nbsp;&nbsp;Minimum</a></Box><Spacer />
                                      <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.minMinutes)}</Box><Spacer />
                                      <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.minMinutes, stateData.prevMinMinutes, true, formatMinutes)}</Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='6'>
                                      <Box w='50%' onClick={() => updateDisChart('DEFECT_TIME_IN_STATE', `Median Defect Time In State - ${stateData.state} (Minutes)`, stateData.state, 'MEDIAN')}><a href='#dischart'>&nbsp;&nbsp;Median</a></Box><Spacer />
                                      <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.medianMinutes)}</Box><Spacer />
                                      <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.medianMinutes, stateData.prevMedianMinutes, true, formatMinutes)}</Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='6'>
                                      <Box w='50%' onClick={() => updateDisChart('DEFECT_TIME_IN_STATE', `Defect Time In State Std Deviation - ${stateData.state} (Minutes)`, stateData.state, 'STANDARD_DEVIATION')}><a href='#dischart'>&nbsp;&nbsp;Std Deviation</a></Box><Spacer />
                                      <Box w='25%' textAlign='right' paddingRight={4}>{formatMinutes(stateData.stdDevMinutes)}</Box><Spacer />
                                      <Box w='20%' alignItems='center' paddingTop='2px'>{genDiffIcon(stateData.stdDevMinutes, stateData.prevStdDevMinutes, true, formatMinutes)}</Box>
                                      <Box w='5%'>&nbsp;</Box>
                                  </Flex>
                                  <Flex h='2'>
                                      <Box w='100%'>&nbsp;</Box><Spacer />
                                  </Flex>
                              </React.Fragment>
                          ))}
                      </Box>
                  </Flex>
              </CardBody>
          </Card>
                </GridItem>
                <GridItem colSpan={2}>
                    <Box w='95%' h='100%'>
                        <Card bg='whitesmoke' alignItems='center' h='100%'>
                            {disState ?
                                <MetricLineChart id="dischart" scanId={scanId} metric={disMetricName} label={disMetricLabel} numDataPoints={10} group={groupData.group} state={disState} statsType={disStatsType}/>
                                :
                                <Text paddingTop='100px'>Select a Defect Time In State Metric</Text>}
                        </Card>
                    </Box>
                </GridItem>
            </Grid>
        </Container>
    );

}