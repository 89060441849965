import './App.css';
import {
    ChakraProvider, extendTheme,
} from "@chakra-ui/react";
import { theme as proTheme } from '@chakra-ui/pro-theme'

import {Dashboard} from "./Dashboard";
import {QueryClient, QueryClientProvider} from "react-query";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import axios from "axios";

const queryClient = new QueryClient();

const theme = extendTheme(proTheme, {
    styles: {
        global: {
            body: {
                bg: 'blue.700',
            },
        },
    },
})

function App() {

    if (process.env.REACT_APP_API_BASE_URL) {
        window._env_.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    }

    if (process.env.REACT_APP_AUTH_DOMAIN) {
        window._env_.REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    }

    if (process.env.REACT_APP_AUTH_CLIENT_ID) {
        window._env_.REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    }

    if (process.env.REACT_APP_AUTH_AUDIENCE) {
        window._env_.REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
    }

    axios.defaults.baseURL = window._env_.REACT_APP_API_BASE_URL;

  return (
      <Auth0Provider
          domain={window._env_.REACT_APP_AUTH_DOMAIN}
          clientId={window._env_.REACT_APP_AUTH_CLIENT_ID}
          authorizationParams={{
              redirect_uri: window.location.origin,
              audience: window._env_.REACT_APP_AUTH_AUDIENCE
          }}
      >
      <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
              <Dashboard/>
          </QueryClientProvider>
      </ChakraProvider>
      </Auth0Provider>
  );
}

export default App;
