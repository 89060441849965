import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, Button,
    Card,
    CardBody,
    CardHeader, Container, Flex,
    Grid,
    GridItem,
    Heading, Icon, SimpleGrid, Spacer,
    Stack,
    StackDivider,
    Text,
    useBreakpointValue, useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import {useQuery} from "react-query";
import * as React from "react";
import {DeveloperCard} from "./DeveloperCard";
import {LoadingView} from "../shared/LoadingView";
import {ErrorView} from "../shared/ErrorView";
import {NoDataView} from "../shared/NoDataView";

export const DeveloperMetrics = ({scanId}) => {

    const [selectedDev, setSelectedDev] = React.useState(null);
    const [devData, setDevData] = React.useState(null);

    const getScanCodeInfo = async() => {
        const response = await axios.get(`/api/v1/scan/scm/${scanId}`);
        setSelectedDev(null);
        setDevData(null);
        return response.data;
    }

    const {isLoading, isError, data, error, refetch} = useQuery(['scan-scm', scanId], getScanCodeInfo);

    if (isLoading) return (
        <LoadingView/>
    );

    if (isError) return (
        <ErrorView message = {error}/>
    );

    if (!data) return (
        <NoDataView/>
    );

    const isButtonActive = (id) => {
        return id === selectedDev;
    }

    const changeDeveloper = (data) => {
        setDevData(data);
        setSelectedDev(data.developer.id);
    }

    if (data && selectedDev === null) {
        setSelectedDev(data.developerData[0].developer.id);
        setDevData(data.developerData[0]);
    }

    return (
            <Container py="5" alignContent='center'>
                <Box px="8">
                    {data && data.developerData.map((devData) =>
                        <React.Fragment key = {devData.developer.email}>
                            <Button colorScheme='whiteAlpha' variant='solid' size='sm' margin={1} isActive={isButtonActive(devData.developer.id)} onClick={()=>changeDeveloper(devData)}>{devData.developer.lastName},&nbsp;{devData.developer.firstName}</Button>
                        </React.Fragment>
                    )}
                </Box>
                {data ? <>
                    <br/>
                    <DeveloperCard devData={devData} scanId={scanId}/>
                </> : <>No Data</>
                }
            </Container>
    );
}